// import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";

function App() {
  return (
    <Home />
    // <Routes>
    //   <Route path="/" element={ <Home/> }/>
    //   <Route path="/support.html" element={ <Support/> }/>
    // </Routes>
  );
}

export default App;